$positionPromptUnderInput: -62px;
$promptTranslateTop: -180px;

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

.form {
  position: relative;
  padding: 32px 24px 40px;
  box-sizing: border-box;

  &__big {
    .form__in {
      max-width: 520px;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 32px;
    left: 32px;
    width: 72px;
    height: 72px;
    background: url(../../../images/decor_top-left.svg) no-repeat center center;
  }

  @media #{$desktop-down} {
    &::before {
      display: none;
    }
  }

  &_blue {
    background: #ffffff;
    border: 1px solid $grayBlue3;
    border-radius: 20px;

    &::before {
      background: url(../../../images/decor_top-left_blue.svg) no-repeat center center;
    }
  }

  &_top {
    margin-top: 64px;
  }

  &__in {
    max-width: 488px;
    margin: 0 auto;
  }

  &__title {
    margin-bottom: 24px;
    font-family: $fontHeavy, Arial, Helvetica, sans-serif;
    font-size: 64px;
    line-height: 1.15;
    color: $gray5;
    text-transform: uppercase;
  }

  &__desc {
    margin-bottom: 40px;
    font-size: 24px;
    line-height: 1.5;
    color: $grayBlue5;
  }

  &__field {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    &_radiogroup {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      height: 52px;
      border: 2px solid $grayBlue3;
      box-sizing: border-box;
      border-radius: 10px;
      overflow: hidden;
    }
  }

  &__input,
  &__textarea {
    position: relative;
    z-index: 10;
    width: 100%;
    padding: 0 24px;
    border: 2px solid $grayBlue3;
    box-sizing: border-box;
    border-radius: 12px;
    font-family: $fontBook, Arial, Helvetica, sans-serif;
    font-size: 24px;
    line-height: 52px;
    color: $grayBlue6;
    outline: none;
    transition: all 0.2s ease-out;
    -webkit-appearance: none;

    @include placeholder {
      color: $grayBlue4;
    }

    &:focus {
      border-color: $grayBlue4;
      transition: all 0.2s ease-out;
    }
  }

  &__input {
    height: 56px;
    line-height: 24px;
    padding-bottom: 3px;
  }

  &__textarea {
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    min-height: 168px;
    line-height: 1.45;
    resize: none;
    overflow: hidden;
  }

  &__error-text {
    text-align: start;
    padding: 0 8px;
    box-sizing: border-box;
    font-family: $fontBook, Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 1.45;
    color: $red;
  }

  &__attach-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px;

    &.error {
      border-radius: 12px;
      border: 2px solid $red;
      transition: all 0.2s ease-out;
    }
  }

  &__attach-btn {
    position: relative;
    display: inline-block;
    margin: 0;
    padding-left: 32px;
    background: transparent;
    border: none;
    font-family: $fontHeavy, Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: $gray5;
    text-transform: uppercase;
    cursor: pointer;
    outline: none;
    transition: all 0.2s ease-out;

    &:hover {
      color: $grayBlue5;
      transition: all 0.2s ease-out;

      .form__attach-icon svg path {
        fill: $grayBlue5;
        transition: all 0.2s ease-out;
      }
    }
  }

  &__attach-comment {
    font-size: 18px;
    line-height: 26px;
    color: $grayBlue4;
    padding-left: 32px;
    margin-top: 4px;
  }

  &__attach-icon {
    position: absolute;
    display: block;
    top: 1px;
    left: 0;

    svg path {
      fill: $gray5;
      transition: all 0.2s ease-out;
    }
  }

  &__btn-wrapper {
    margin-top: 32px;
  }

  &__file {
    display: none;
  }

  &__notification {
    position: relative;
    z-index: 2;
    text-align: center;
    font-size: 18px;
    line-height: 1.45;
    color: $grayBlue4;
    max-width: 390px;
    margin: 0 auto;

    @media #{$tablet-down} {
      max-width: 330px;
    }
  }

  &__link {
    color: $gray5;
    transition: all 0.2s ease-out;

    &:hover {
      color: $grayBlue5;
    }
  }

  &__label-radio {
    display: inline-block;
    border-right: 2px solid $grayBlue3;
    box-sizing: border-box;
    text-align: center;
    width: 50%;
    font-family: $fontMedium, Arial, Helvetica, sans-serif;
    font-size: 24px;
    line-height: 1;
    color: $grayBlue4;
    cursor: pointer;
    background: transparent;

    &:last-child {
      border-right: 0;
    }

    &:hover {
      .form__text-radio {
        background-color: $grayBlue2;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  &__radio {
    position: absolute;
    visibility: hidden;

    &:checked + .form__text-radio {
      color: $grayBlue5;
      background: $grayBlue3;
    }
  }

  &__text-radio {
    display: block;
    line-height: 48px;
    padding: 0 5px;
    box-sizing: border-box;
    font-size: 24px;
    color: $grayBlue4;
    background-color: #fff;
    transition: all 0.2s ease-in-out;
    user-select: none;
  }

  &__submit-text {
    line-height: 1;

    &_mobile {
      display: none;
    }
  }

  &__prompt {
    position: relative;
    top: $positionPromptUnderInput;
    margin-bottom: $positionPromptUnderInput;
    padding: 52px 14px 12px 24px;
    border: 2px solid $grayBlue3;
    background: $grayBlue1;
    border-radius: 12px;
    transition: all 150ms;
    cursor: pointer;
    text-align: left;

    & .drop-up-icon path {
      transition: 100ms ease-in-out;
    }

    &:hover .drop-up-icon path {
      fill: $blue2;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &.hidden {
      top: $promptTranslateTop;
    }

    &-text {
      color: $grayBlue5;
      font-size: 18px;
      line-height: 1.3;
      transition: 150ms ease-in-out;

      &.hidden {
        opacity: 0;
      }
    }

    &-icon {
      width: 25px;
      height: 9px;
    }
  }

  @media #{$desktop-small-down} {
    &__in {
      //max-width: 360px;
    }

    &__title {
      margin-bottom: 16px;
      font-size: 40px;
      line-height: 1.3;
    }

    &__desc {
      margin-bottom: 32px;
      font-size: 18px;
      line-height: 1.45;
    }

    &__input {
      height: 48px;
      font-size: 18px;
    }

    &__text-radio,
    &__textarea {
      font-size: 18px;
    }

    &__text-radio {
      height: 44px;
      line-height: 44px;
    }

    &__field_radiogroup {
      height: 48px;
    }

    &__error-text {
      font-size: 16px;
    }

    &__attach-btn {
      margin: 0;
      font-size: 16px;
      line-height: 20px;
      padding-left: 28px;
    }

    &__attach-icon {
      width: 20px;
      height: 22px;
      top: -1px;

      svg {
        width: 100%;
      }
    }

    &__attach-comment {
      padding-left: 28px;
    }

    &__attach-container {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media #{$tablet-down} {
    &::before {
      display: none;
    }

    &__in {
      max-width: 100%;
    }
  }

  @media #{$mobile-down} {
    &__attach-btn {
      text-align: left;
    }

    &__submit-text {
      &_desktop {
        display: none;
      }

      &_mobile {
        display: block;
      }
    }

    &__prompt {
      &-icon {
        width: 40px;
        height: 20px;
      }
    }

    &__send-icon-wrapper {
      display: none;
    }
  }

  @media screen and (max-width: 400px) {
    &__text-radio {
      padding: 4px 30px;
      line-height: 1;
    }

    &__prompt {
      &-icon {
        width: 50px;
        height: 25px;
      }
    }
  }
}

.phone-wrapper {
  overflow: hidden;
  border-radius: 12px;
  margin-bottom: 16px;

  input {
    box-shadow: 0 6px 12px rgba(99, 120, 153, 0.08);
    transition: all 150ms;

    &.hidden {
      box-shadow: 0 6px 12px rgba(99, 120, 153, 0);
    }
  }
}
