@mixin container-element {
  outline: 1px solid $grayBlue3;
  border-radius: 24px;
  background: rgba(0, 0, 0, 0);
  box-shadow: 0px 20px 64px 0px rgba(99, 120, 153, 0.08);
  backdrop-filter: blur(40px);
}

.contacts {
  background: #f9fafd;
  .first {
    position: relative;

    &__background__image {
      position: absolute;
      top:0;
      right: 0;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: flex-end;
      background-color: $greyLight;
      @media #{$desktop-small-down} {
        width: 70%;
      }
      img {
        position: absolute;
      }
    }

    @media #{$desktop-small-down} {
      &__background__image {
        justify-content: flex-start;

        img {
          width: 100%;
          top: auto;
          left: auto;
          object-fit: contain
        }
      }
    }

    &__in {
      padding-top: 96px;
      padding-bottom: 196px;

      @media #{$desktop-small-down} {
        padding-top: 64px;
        padding-bottom: 64px;
      }
    }

    &__main-first {
      padding: 0;
      display: flex;
      justify-content: space-between;
    }

    &__slogan-block {
      width: 38.5%;
      margin-right: 45px;

      @media #{$desktop-pre-mid-down} {
        width: 100%;
        margin-right: 0;
      }

      @media #{$mobile-down} {
        width: 100%;
        margin-right: 0;
      }
    }

    &__cards-first {
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-wrap: wrap;
      width: 75%;
      margin-top: 100px;

      @media #{$desktop-pre-mid-down} {
        display: none;
      }
    }

    &__cards-under {
      display: none;
      justify-content: center;
      align-items: stretch;
      flex-wrap: wrap;

      @media #{$desktop-pre-mid-down} {
        display: flex;
      }
    }

    &__benefit-card {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;
      width: 35%;
      min-width: 394px;
      margin-bottom: 24px;
      background: rgba(255, 255, 255, 0.7);
      border: 1px solid $grayBlue3;
      box-sizing: border-box;
      padding: 48px 20px 40px;
      box-shadow: 0px 20px 64px rgba(99, 120, 153, 0.08);
      backdrop-filter: blur(80px);
      border-radius: 20px;

      &:nth-child(2n - 1) {
        margin-right: 24px;
      }

      &:nth-last-child(1), &:nth-last-child(2) {
        margin-bottom: 0;
      }

      @media #{$desktop-down} {
        min-width: 268px;
        min-height: 268px;
      }

      @media #{$desktop-pre-mid-down} {
        width: calc((100% - 24px) * 0.5);
        min-height: 180px;
      }

      @media #{$desktop-small-down} {
        backdrop-filter: none;
      }

      @media #{$tablet-down} {
        &:nth-child(2n - 1) {
          margin-right: 0;
        }

        &:nth-last-child(2) {
          margin-bottom: 16px;
        }

        min-width: auto;
        width: 100%;
        padding: 16px 24px;
        height: auto;
        min-height: 110px;
        justify-content: normal;
        flex-direction: row;
        margin-bottom: 16px;
      }

      @media #{$mobile-small-down} {
        min-height: auto;
      }
    }

    &__icon {
      min-width: 56px;
      min-height: 56px;
      background: $blue1 url("../../../images/check_mark.svg") center no-repeat;
      box-shadow: 0px 4px 24px rgba(36, 154, 239, 0.2);
      border-radius: 49px;
      margin-bottom: 16px;

      @media #{$desktop-small-down} {
        background-size: 15px 12px;
        min-width: 40px;
        min-height: 40px;
      }

      @media #{$tablet-down} {
        margin-bottom: 0;
        margin-right: 24px;
      }
    }

    &__text {
      font-family: $fontMedium, Arial, Helvetica, sans-serif;
      font-size: 24px;
      width: 100%;
      line-height: 1.5;
      text-align: center;
      color: $gray5;

      @media #{$only-ie} {
        width: 100%;
      }

      @media #{$desktop-small-down} {
        font-size: 18px;
        line-height: 1.45;
      }

      @media #{$mobile-small-down} {
        word-break: break-all;
      }
    }
  }
  &__in.section__in {
    padding: 96px 88px 32px;

    @media #{$desktop-down} {
      padding: 96px 24px 32px 24px;
    }

    @media #{$desktop-small-down} {
      padding: 56px 32px 48px;
    }

    @media #{$tablet-down} {
      padding: 56px 32px 0px;
    }

    @media #{$mobile-down} {
      padding: 56px 16px 0;
    }
  }

  &__content-form {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 24px;
    justify-content: center;
    @media #{$tablet-down} {
      flex-direction: column-reverse;
    }
  }

  &__right-side{
    display: flex;
    flex-direction: column;
    min-width: 507.5px;
    max-width: 600px;
    width: 600px;
    margin-right: 24px;
    @media #{$desktop-small-down} {
      min-width: 369px;
      max-width: 507.5px;
      width: 507.5px;
    }
    @media #{$tablet-down} {
      margin-right: 0;
      max-width: 100%;
      min-width: 100%;
      margin-bottom: 24px;
    }
    @media #{$mobile-down} {
      min-width: 100%;
    }
  }

  &__info {
    width: calc((100% - 40px) * 0.7);
    margin-right: 60px;
    max-width: 680px;

    @media #{$desktop-down} {
      margin-right: 0;
    }
  }
  .form {
    padding: 40px 48px 48px;
    margin-bottom: 0;
    height: 100%;
    @media #{$desktop-small-down} {
      padding: 24px 32px 32px 32px;
    }

    @media #{$mobile-down} {
      padding: 0;
    }
  }
  &__form-container {
    width: 100%;
    height: min-content;
    margin-bottom: 96px;
    padding: 40px 48px 48px;

    @include container-element;

    @media #{$mobile-down} {
      outline: 0px;
    }

    .form__title {
      margin-bottom: 40px;
      font-family: $fontDemi, Arial, Helvetica, sans-serif;
      font-size: 40px;
      line-height: 1.3;
      text-transform: none;
      @media (min-width: 1544px) {
        padding: 20px 0 0 20px;
      }
    }
  }
  &__list {
    @media #{$tablet-down} {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .first {
        width: 48%;
        .el-form_contact {
          margin-bottom: 24px;
        }
      }
      .second {
        width: 48%;
      }
      .third {
        width: 100%;
      }
      .contacts__item {
        margin: 0;
        margin-bottom: 24px;
      }
    }
    @media (max-width: 600px) {
      flex-direction: column;

      .first {
        width: 100%;
      }
      .second {
        width: 100%;
      }
    }

  }
  &__desc {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 40px;
    margin-bottom: 48px;
    color: $grayBlue5;
    max-width: 640px;
  }


  &__item {
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 1.5;
    @include container-element;
    padding: 40px 48px;
    @media #{$desktop-small-down} {
      padding: 24px 32px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  .requisites {
    display: grid;
    grid-template-columns: 1fr 1fr;
    white-space: nowrap;

    @media screen and (max-width: 1185px) {
      display: flex;
      flex-direction: column;
    }
    @media #{$desktop-small-down} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      white-space: nowrap;
    }
    @media screen and (max-width: 950px) {
      display: flex;
      flex-direction: column;
    }
    @media #{$tablet-down} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      white-space: nowrap;
    }
    @media screen and (max-width: 530px) {
      display: flex;
      flex-direction: column;
    }
    span {
      margin-bottom: 8px;
      font-size: 24px;
      line-height: 150%;
      @media #{$desktop-small-down} {
        font-size: 18px;
      }
    }
  }

  &__cont-mail-phone {
    display: flex;

    .el-form_contact {

      &:first-child {
        margin-right: 56px;
        @media screen and (max-width: 982px) {
          margin-bottom: 24px;
        }
      }
    }
    @media screen and (max-width: 982px) {
      flex-direction: column;
    }
  }

  &__link {
    font-family: $fontMedium, Arial, Helvetica, sans-serif;
    font-size: 24px;
    line-height: 1.5;
    color: $gray5;
    border-bottom: 1px solid $gray5;
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:hover {
      color: $grayBlue5;
      border-bottom-color: $grayBlue5;
    }
  }
  &__item-desc {
      @media (max-width: 1214px) {
        .phone {
          font-size: 18px;
        }
        .email {
          font-size: 18px;
        }
      }
    @media #{$tablet-down} {
      font-size: 18px;
    }
  }
  &__item-title {
    margin-bottom: 8px;
    font-family: $fontDemi, Arial, Helvetica, sans-serif;
    line-height: 1.5;
    color: $grayBlue5;
    max-width: 280px;
  }

  &__item-desc,
  &__schedule {
    line-height: 1.5;
  }

  .has-margin {
    margin-bottom: 32px
  }

  @media #{$desktop-down} {
    &__content-wrapper {
      flex-wrap: nowrap;
      gap: 20px;
    }

    &__form-container {
      width: 100%;
    }

    &__info {
      min-width: 507.5px;
      max-width: 680px;
      @media #{$desktop-small-down} {
        min-width: 272px;
        max-width: 507.5px;
      }
      @media #{$tablet-down} {
        min-width: 100%;
        max-width: 100%;
      }
    }

    &__desc {
      max-width: 640px;
    }
  }

  @media #{$desktop-down} {
    &__form-container {

      .form__title {
        font-family: $fontDemi, Arial, Helvetica, sans-serif;
        font-size: 32px;
        line-height: 1.3;
      }
    }
  }

  @media #{$desktop-small-down} {
    &__desc {
      max-width: 400px;
      font-size: 18px;
      line-height: 1.45;
    }

    &__desc {
      margin-bottom: 40px;
      
      @media #{$mobile-down} {
        margin-bottom: 24px;
      }
    }

    &__item {
      line-height: 1.45;
    }
    &__link {
      font-size: 18px;
      line-height: 1.45;
    }

    &__item {
      margin-bottom: 24px;
    }

    &__info {
    }

    &__item-title,
    &__item-desc,
    &__schedule {
      line-height: 1.45;
    }
  }

  @media #{$tablet-down} {
    &__content-wrapper {
      flex-wrap: wrap;
      padding-bottom: 32px;
    }

    &__form-container.form {
      border: none;
      box-shadow: none;

      .form__title {
        width: 100%;
      }
    }
  }
  @media #{$mobile-down} {
    &__content-wrapper {
      flex-wrap: wrap;
      padding-bottom: 12px;
    }
  }
}
