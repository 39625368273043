.technologies {
  &__list {
    display: flex;
  }

  &__item {
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-right: 40px;
      width: calc((100% - 40px) * 0.2367);
    }

    &:last-child {
      width: calc((100% - 40px) * 0.7633);

      .technologies {
        &__inner-list {
          display: flex;
        }

        &__inner-item {
          width: 33.3%;
        }
      }
    }
  }

  &__inner-list {
    padding-top: 48px;
    padding-bottom: 48px;
    flex-grow: 1;
    background: #fff;
    border: 1px solid $grayBlue3;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.06);
  }

  &__item-title {
    margin-bottom: 32px;
    text-align: center;
    font-family: $fontDemi, Arial, Helvetica, sans-serif;
    font-size: 40px;
    line-height: 1.3;
    color: $gray5;
  }

  &__item-subtitle {
    margin-bottom: 54px;
    text-align: center;
    font-family: $fontDemi, Arial, Helvetica, sans-serif;
    font-size: 32px;
    line-height: 1.3;
    color: $gray5;
  }

  &__inner-subitem {
    margin-bottom: 48px;
    transition: opacity 0.2s ease-in-out;

    &:last-child {
      margin-bottom: 0;
    }

    img {
      margin: 0 auto;
      display: block;
      height: 48px;

      @media #{$desktop-small-down} {
        height: 32px;
      }

      &.ReactJs {
        width: 163px;
        @media #{$desktop-small-down} {
          width: 108px;
        }
      }
      &.React.Native {
        width: 206px;
        @media #{$desktop-small-down} {
          width: 137px;
        }
      }
      &.AngularJS {
        width: 180px;
        @media #{$desktop-small-down} {
          width: 120px;
        }
      }
      &.VueJS {
        width: 126px;
        @media #{$desktop-small-down} {
          width: 84px;
        }
      }
      &.svetle {
        width: 196px;
        @media #{$desktop-small-down} {
          width: 130px;
        }
      }
      &.Django {
        width: 136px;
        @media #{$desktop-small-down} {
          width: 130px;
        }
      }
      &.Flask {
        width: 103px;
        @media #{$desktop-small-down} {
          width: 69px;
        }
      }
      &.Asyncio {
        width: 119px;
        @media #{$desktop-small-down} {
          width: 79px;
        }
      }
      &.Django {
        width: 136px;
        @media #{$desktop-small-down} {
          width: 90px;
        }
      }
      &.Symphony {
        width: 193px;
        @media #{$desktop-small-down} {
          width: 128px;
        }
      }
      &.Laravel {
        width: 195px;
        @media #{$desktop-small-down} {
          width: 130px;
        }
      }
      &.Zend {
        width: 200px;
        @media #{$desktop-small-down} {
          width: 133px;
        }
      }
      &.Yii {
        width: 200px;
        @media #{$desktop-small-down} {
          width: 133px;
        }
      }
      &.Next {
        width: 135px;
        @media #{$desktop-small-down} {
          width: 90px;
        }
      }
      &.Express {
        width: 190px;
        @media #{$desktop-small-down} {
          width: 126px;
        }
      }
      &.Strapi {
        width: 170px;
        @media #{$desktop-small-down} {
          width: 113px;
        }
      }
      &.Spring {
        width: 160px;
        @media #{$desktop-small-down} {
          width: 107px;
        }
      }
      &.Hibernate {
        width: 157px;
        @media #{$desktop-small-down} {
          width: 105px;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    &__list {
      flex-wrap: wrap;
    }

    &__item {
      &:nth-child(n) {
        margin-right: 0;
        margin-bottom: 40px;
        width: 100%;

        .technologies {
          &__inner-list {
            padding-left: 12.3%;
            padding-right: 12.3%;
          }
        }
      }

      &:first-child {
        .technologies {
          &__inner-sublist {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: -16px -28px;
          }

          &__inner-subitem {
            margin: 16px 28px;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media #{$desktop-small-down} {
    &__inner-list {
      padding-top: 32px;
      padding-bottom: 32px;
    }

    &__item-title {
      margin-bottom: 24px;
      font-size: 32px;
      line-height: 1.3;
    }

    &__item-subtitle {
      margin-bottom: 32px;
      font-size: 24px;
      line-height: 1.32;
    }

    &__inner-subitem {
      height: 32px;
      margin-bottom: 32px;
    }

    &__item {
      &:first-child {
        .technologies {
          &__inner-sublist {
            margin: -16px 12px;
          }

          &__inner-subitem {
            margin: 16px 12px;
          }
        }
      }

      &:nth-child(n) {
        margin-bottom: 32px;

        .technologies {
          &__inner-list {
            padding-left: 5%;
            padding-right: 5%;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (max-width: 900px) {
    &__item {
      &:last-child {
        .technologies {
          &__inner-list {
            padding-left: 13%;
            padding-right: 13%;
          }

          &__inner-item {
            &:first-child {
              width: 26%;
            }

            &:nth-child(2) {
              width: 41%;
            }

            &:last-child {
              width: 33%;
            }
          }
        }
      }
    }
  }

  @media #{$tablet-down} {
    &__item {
      &:nth-child(n) {
        .technologies {
          &__inner-list {
            padding-left: 28px;
            padding-right: 28px;
          }
        }
      }

      &:last-child {
        .technologies {
          &__inner-list {
            flex-wrap: wrap;
          }

          &__inner-item {
            margin-bottom: 32px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          &__inner-item:nth-child(n) {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
          }

          &__item-subtitle {
            width: 100%;
          }

          &__inner-sublist {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: -16px -12px;
            width: 100%;
          }

          &__inner-subitem {
            margin: 16px 12px;
          }
        }
      }
    }
  }

  @media #{$mobile-down} {
    .technologies__item {
      &:nth-child(n) {
        .technologies {
          &__inner-list {
            display: block;
          }

          &__inner-sublist {
            display: block;
            margin: 0 auto;
          }

          &__inner-subitem {
            margin: 0 0 32px 0;

            &:last-child {
              margin-bottom: 0;
            }
          }

          &__item-subtitle {
            text-align: center;
          }
        }
      }
    }
  }
}
