@import "includes/clear";
@import "includes/fonts";
@import "includes/breakpoints";
@import "includes/theme";
@import "includes/buttons";

@import "includes/common-styles";

@import "includes/menu/siteMenu";
@import "includes/menu/socialMenu";
@import "includes/menu/menuList";

@import "includes/first/first";
@import "includes/first/partners";
@import "includes/about/about";

@import "includes/outstaffing/benefits/outstaffBenefits";

@import "includes/difference/difference";
@import "includes/difference/differenceMobile";
@import "includes/services/services";
@import "includes/features/features";
@import "includes/technologies/technologies";
@import "includes/risks/risks";
@import "includes/team/team";
@import "includes/team/teamSliderMobile";
@import "includes/team/teamSliderDesc";
@import "includes/team/teamGrid";
@import "includes/market/market";
@import "includes/market/market_bottomCard";
@import "includes/market/outlineBadge";
@import "includes/blog/blog";
@import "includes/blog/blog_temp";
@import "includes/experience/experience";
@import "includes/case/case";
@import "includes/case/junior-case";
@import "includes/reviews/reviews";
@import "includes/specproject/specproject";
@import "includes/tehnicheskiy-audit-frontend/consult";

@import "includes/coders/tutoring";
@import "includes/coders/taskManagement";
@import "includes/coders/technologies";
@import "includes/coders/projects";

@import "includes/contacts/contacts";
@import "includes/contacts/map";

@import "includes/breadcrumbs/breadcrumbs";

@import "includes/forms/forms";
@import "includes/forms/attachment";

@import "includes/modals/modal";

@import "includes/footer/footer";

@import "includes/404/404";
