.coder-task-management {
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: -20px;
  }

  &__item {
    position: relative;
    margin: 20px;
    padding: 48px 40px;
    box-sizing: border-box;
    width: calc((100% - 81px) / 2);
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.06);
  }

  &__item-title {
    margin-bottom: 16px;
    font-family: $fontDemi, Arial, Helvetica, sans-serif;
    font-size: 32px;
    line-height: 1.3;
    color: $gray5;
  }

  &__item-desc {
    font-size: 24px;
    line-height: 1.5;
  }

  @media screen and (max-width: 1300px) {
    &__list {
      display: block;
      margin: 0;
    }

    &__item {
      margin: 0 0 40px -0px;
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media #{$desktop-small-down} {
    &__item {
      &:nth-child(n) {
        margin-bottom: 24px;
        padding: 32px 24px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__item-title {
      margin-bottom: 8px;
      font-size: 24px;
      line-height: 1.32;
    }

    &__item-desc {
      font-size: 18px;
      line-height: 1.45;
    }
  }

  @media #{$mobile-down} {
    &__item {
      &:nth-child(n) {
        padding: 32px 24px;
      }
    }
  }
}
