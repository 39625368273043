.about {
  color: $gray5;

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: -20px -20px -76px -20px;
  }

  &__item {
    width: calc((100% - 480px)/12*5 + 160px);
    margin: 20px 20px 76px 20px;

    &:nth-child(3n) {
      width: calc((100% - 480px)/12*3 + 80px);
    }

    &:nth-child(3n+1) {
      width: calc((100% - 480px)/12*4 + 120px);
    }
  }

  &__item-title {
    margin-bottom: 20px;
    font-family: $fontDemi, Arial, Helvetica, sans-serif;
    font-size: 48px;
    line-height: 1;
  }

  &__item-desc {
    font-size: 24px;
    line-height: 1.5;
  }

  @media screen and (max-width: 1200px) {
    &__list {
      margin-bottom: -44px;
    }

    &__item:nth-child(n) {
      margin-bottom: 44px;
      width: calc((100% - 81px) / 2);
    }
  }

  @media #{$desktop-small-down} {
    &__list {
      margin: -12px -12px -20px -12px;
    }

    &__item:nth-child(n) {
      margin: 12px 12px 20px 12px;
      width: calc((100% - 49px) / 2);
    }

    &__item-title {
      margin-bottom: 8px;
      font-size: 32px;
      line-height: 1.3;
    }

    &__item-desc {
      font-size: 18px;
      line-height: 1.45;
    }
  }

  @media screen and (max-width: 600px) {
    &__list {
      margin-left: 0;
      margin-right: 0;
    }

    &__item:nth-child(n) {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  }
}