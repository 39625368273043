.map-wrapper {
  display: flex;
  flex-direction: row;
}

.map {
  width: 100%;
  height: 500px;
  border-radius: 24px;

  .gmnoprint,
  .gm-style-cc {
    display: none !important;
  }
}

@media #{$desktop-down} {
  .address-work {
    display: none;
  }
  .map {
    width: 100%;
  }
}

@media #{$tablet-down} {
  .map-wrapper {
    flex-direction: column;
  }
  .map {
    width: 100%;
    height: 208px;
  }
}

@media #{$mobile-down} {
  .map-wrapper {
    flex-direction: column;
    padding-left: 0;
  }

  .map {
    min-width: 232px;
  }
}