.menu-list {
  position: fixed;
  background: white;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: none;

  &.visible {
    @media #{$desktop-middle-down} {
      display: flex !important;
      align-items: center;
      align-content: center;
      justify-content: center;
    }

    @media #{$mobile-down} {
      align-items: flex-start;
    }
  }

  &__content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;

    @media #{$desktop-middle-down} {
      display: block;
      width: auto;
      box-sizing: border-box;
    }

  }

  &__body {
    position: relative;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: center;

    @media #{$desktop-middle-down} {
      display: block;
      width: auto;
      box-sizing: border-box;
    }

    @media #{$mobile-down} {
      padding-left: 48px;
      padding-right: 16px;

      height: auto;
      padding-bottom: 56px;
    }

  }

  &__col {
    position: relative;
    margin-right: 96px;

    &:last-of-type {
      margin-right: 0;
    }

    @media #{$desktop-middle-down} {
      margin: 32px 0;

      &:first-child {
        margin-top: 96px;
      }

      &:last-child {
        margin-bottom: 64px;

        .menu-list__cell {
          margin-bottom: 32px;
        }
      }
    }

    @media #{$desktop-small-down} {
      margin: 24px 0;

      &:first-child {
        margin-top: 56px;
      }

      &:last-child {
        margin-bottom: 32px;

        .menu-list__cell {
          margin-bottom: 24px;
        }
      }
    }

    @media #{$mobile-down} {
      &:last-child {
        margin-bottom: -24px;

        .menu-list__cell {
          margin-bottom: 24px;
        }
      }
    }

  }

  &__cell {
    position: relative;
    margin-bottom: 32px;
    height: 40px;

    &:last-of-type {
      margin-bottom: 0;
    }

    @media #{$desktop-small-down} {
      margin-bottom: 24px;
    }

    @media #{$tablet-down} {
      height: auto;
    }

    &.empty {
      @media #{$desktop-middle-down} {
        display: none;
      }
    }

  }

  &__link {
    position: relative;
    display: block;
    font-family: $fontDemi, Arial, Helvetica, sans-serif;
    font-size: 32px;
    line-height: 40px;
    color: $gray5;
    transition: .2s all ease-out;
    text-decoration: none;
    outline: none;

    opacity: 0;
    transform: translateX(50px);

    &.visible {
      opacity: 1;
      transform: translateX(0);
    }

    &:hover {
      color: $grayBlue5;
    }

    @media #{$desktop-small-down} {
      font-size: 24px;
      line-height: 36px;
    }
  }

  &__close {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 28px;
    right: 28px;
    z-index: 2;
    overflow: hidden;

    @media #{$desktop-middle-down} {
      position: fixed;
    }

  }

}
