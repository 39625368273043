.consult {
  .section__in {
    padding-top: 144px;
    padding-bottom: 144px;

    @media #{$desktop-small-down} {
      padding-top: 96px;
      padding-bottom: 96px;
    }
  }

  & + .footer {
    .footer__in {
      padding-top: 0;
    }
  }
}

.consult-wrapper {
  position: relative;
  background: white;
  border-radius: 20px;
  border: 1px solid $grayBlue3;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.06);
  padding: 64px 32px 48px;

  @media #{$desktop-middle-down} {
    padding: 64px 32px 653px;
  }

  @media #{$desktop-small-down} {
    padding: 32px 24px 562px;
  }

  @media #{$tablet-down} {
    padding-bottom: 24px;
  }

  &__consult-image {
    display: flex;
    align-items: flex-end;
    width: 690px;
    height: 605px;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: hidden;

    picture {
      width: 100%;
      height: 100%;
      display: flex;

      img {
        width: 690px;
        height: 605px;
        object-fit: contain;
        width: 100%;
        transform: translateY(25px);
      }
    }

    @media #{$desktop-small-down} {
      width: 751px;
      height: 522px;

      picture > img {
        width: 647px;
        height: 522px;
        transform: none;
      }
    }

    @media #{$tablet-down} {
      position: relative;
      width: calc(100% + 48px);
      height: auto;
      margin: 40px -24px -24px;

      picture > img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &::before {
    content: "";
    display: block;
    width: 72px;
    height: 72px;
    position: absolute;
    top: 32px;
    right: 32px;
    background: url(../../../images/consult/decor_1.svg) no-repeat 50% 50%;
    background-size: contain;

    @media #{$desktop-small-down} {
      display: none;
    }
  }

  &::after {
    content: "";
    display: block;
    width: 52px;
    height: 52px;
    position: absolute;
    bottom: 32px;
    right: 32px;
    background: url(../../../images/consult/decor_2.svg) no-repeat 50% 50%;
    background-size: contain;

    @media #{$desktop-small-down} {
      display: none;
    }
  }
}

.consult-header {
  position: relative;
  text-align: center;

  @media #{$tablet-down} {
    text-align: left;
  }

  &__title {
    font-family: $fontDemi, Arial, Helvetica, sans-serif;
    font-size: 40px;
    line-height: 52px;
    color: $gray5;
    margin-bottom: 16px;

    @media #{$desktop-small-down} {
      font-size: 32px;
      line-height: 42px;
      margin-bottom: 8px;
    }
  }

  &__subtitle {
    font-size: 24px;
    line-height: 36px;
    color: $grayBlue5;
    margin-bottom: 48px;

    @media #{$desktop-small-down} {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 40px;
    }
  }
}

@media #{$desktop-up} {
  .junior-consult .consult-header__subtitle {
    margin-bottom: 96px;
  }
}

.consult-content {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 112px;
  max-width: 1352px;

  @media #{$desktop-middle-down} {
    margin-bottom: 64px;
  }

  @media #{$desktop-small-down} {
    display: block;
    margin-bottom: 40px;
  }

  &__col {
    position: relative;
    flex: 1;
    width: calc(50% - 20px);

    &:first-of-type {
      margin-right: 40px;
    }

    @media #{$desktop-small-down} {
      width: 100%;
      max-width: 648px;
      margin: 0 auto;

      &:first-of-type {
        margin-right: auto;
      }
    }
  }

  &__cell {
    position: relative;
    font-size: 24px;
    line-height: 36px;
    color: $gray5;
    padding: 0 0 0 36px;
    margin-bottom: 32px;

    @media #{$desktop-small-down} {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 24px;
      padding: 0 36px;
    }

    @media #{$mobile-down} {
      padding-right: 0;
    }

    &:last-of-type {
      margin-bottom: 0;

      @media #{$desktop-small-down} {
        margin-bottom: 24px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 5px;
      left: 0;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: $blue2 url(../../../images/arrow_right_short_white.svg) no-repeat center center;

      @media #{$desktop-small-down} {
        top: 1px;
      }
    }
  }
}

.consult-form {
  position: relative;
  max-width: 1352px;
  margin: 0 auto;

  @media #{$desktop-middle-down} {
    display: block;
  }

  &__title {
    font-family: $fontBook, Arial, Helvetica, sans-serif;
    font-size: 26px;
    line-height: 130%;
    color: #090c0f;
    margin-bottom: 16px;
  }

  &__in {
    position: relative;
    width: 100%;
    margin-left: calc(50% + 20px);
    max-width: 564px;
    padding: 0 36px;

    @media #{$desktop-middle-down} {
      max-width: 536px;
      padding: 0 24px;
      margin: 0 auto;
    }

    @media #{$desktop-small-down} {
      width: 100%;
      max-width: 648px;
      padding: 0;
    }

    @media #{$mobile-down} {
      padding: 0;
    }
  }

  &__quote {
    font-size: 24px;
    line-height: 36px;
    color: $grayBlue5;
    margin-bottom: 40px;

    @media #{$desktop-small-down} {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 32px;
    }

    a {
      text-decoration: none;
      color: $grayBlue5;
      border-bottom: 1px solid $grayBlue5;
      transition: all 0.2s ease-out;

      &:hover {
        color: $gray5;
        border-color: $gray5;
      }
    }
  }
}
