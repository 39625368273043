.breadcrumbs {
  &__breadcrumb-list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0;
  }

  &__breadcrumb-list__element {
    position: relative;
    margin-right: 24px;

    font-family: "FuturaPT_Medium", sans-serif;
    font-size: 18px;
    line-height: 145%;
    color: $grayBlue4;

    &:nth-last-child(1) {
      margin-right: 0;
    }
    & > a,
    & > a:active,
    & > a:visited {
      text-decoration: none;
      font-style: normal;
      color: $grayBlue6;
      transition: all 0.2s ease-out;
    }

    & > a:hover {
      color: $grayBlue5;
    }

    &:not(:first-child) {
      & > a,
      & > a:active,
      & > a:visited {
        color: $grayBlue4;
      }
    }
  }

  &__breadcrumb-list__element:not(:last-child)::after {
    position: absolute;
    content: "";
    top: 50%;
    right: -16.5px;
    width: 7.5px;
    height: 7.5px;
    border-bottom: 2px solid #9ba8bb;
    border-right: 2px solid #9ba8bb;
    transform: rotate(-45deg) translateY(-50%);
  }
}
