.close-button {
  display: block;
  position: relative;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  cursor: pointer;
  background: none;
  transition: 0.2s all ease-out;

  svg {
    fill: $grayBlue4;
  }

  &:hover svg {
    fill: $grayBlue5;
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 56px;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  border: 2px solid $blue2;
  text-decoration: none;
  box-sizing: border-box;
  border-radius: 12px;
  font-family: $fontHeavy, Arial, Helvetica, sans-serif;
  font-size: 20px;
  line-height: 52px;
  color: $blue2;
  background: transparent;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  transition: background 0.2s ease-out;

  &::after {
    @include align-items-fix;
    min-height: 52px;
  }

  &_link {
    text-decoration: none;
  }

  svg {
    margin-right: 17px;
    fill: $blue2;
    transition: fill 0.2s ease-out;
  }

  &:hover,
  &:active {
    color: #fff;
    background: $blue2;
    transition: all 0.2s ease-out;

    svg {
      fill: #fff;
      transition: all 0.2s ease-out;
    }

    .preloader::after {
      border-color: #fff transparent #fff transparent;
    }
  }

  &:disabled {
    color: $disabledColor;
    background-color: transparent;
    border-color: $disabledColor;
    cursor: default;

    &:hover,
    &:active {
      color: $disabledColor;
      background: #fff;
      transition: all 0.2s ease-out;

      svg {
        fill: $disabledColor;
      }

      .preloader::after {
        border-color: #fff transparent #fff transparent;
      }
    }
  }

  &_arrows {
    width: 56px;
    height: 56px;
    padding: 0;

    svg {
      margin: 0;
    }
  }

  &_purple {
    color: $purple;
    border-color: $purple;

    &:hover,
    &:focus {
      background: $purple;
    }
  }

  &__text {
    &_mobile {
      display: none;
    }
  }

  .preloader {
    display: none;
  }

  &.loading {
    pointer-events: none;

    svg {
      display: none;
    }

    .preloader {
      display: block;
    }
  }

  @media #{$desktop-small-down} {
    min-height: 48px;
    line-height: 48px;

    &::after {
      min-height: 1px;
    }
    font-size: 16px;
  }

  @media #{$mobile-down} {
    &__text {
      &_desktop {
        display: none;
      }

      &_mobile {
        display: block;
      }
    }
  }
}

.button-line {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-transform: uppercase;
  text-decoration: none;
  background: none;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
  padding: 0 24px;
  margin: 0;
  width: 100%;
  height: 56px;
  color: $blue2;
  border: 2px solid $blue2;
  border-radius: 12px;
  font-family: $fontHeavy;
  font-size: 20px;
  letter-spacing: 0.05em;
  line-height: 1;
  transition: all 0.2s ease-out;

  &__icon {
    display: block;
    width: 32px;
    height: 32px;
    font-size: 32px;
    line-height: 1;
    margin-right: 16px;

    @media #{$tablet-down} {
      width: 30px;
      height: 30px;
      font-size: 30px;
    }
  }

  @media #{$desktop-middle-up} {
    &:hover,
    &:focus {
      color: white;
      fill: white;
      background: $blue2;

      .icon-button {
        fill: white;
      }
    }
  }

  @media #{$tablet-down} {
    height: 48px;
    font-size: 16px;
  }
}

.preloader {
  display: inline-block;
  width: 28px;
  height: 28px;
  margin-right: 17px;

  &::after {
    content: " ";
    display: block;
    width: 28px;
    height: 28px;
    box-sizing: border-box;
    margin: 0;
    border-radius: 50%;
    border: 3px solid $blue2;
    border-color: $blue2 transparent $blue2 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
    transition: border-color 0.2s ease-out;
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
