.attachment {
  position: relative;
  margin: 6px;
  padding: 12px 48px 12px 24px;
  width: calc((100% - 25px) / 2);
  border: 2px solid $grayBlue3;
  box-sizing: border-box;
  border-radius: 12px;
  background: $grayBlue1;

  &__del {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 16px;
    height: 16px;
    background: transparent;
    border: none;
    background: none;
    cursor: pointer;
    outline: none;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__filename {
    margin-bottom: 4px;
    font-family: $fontMedium, Arial, Helvetica, sans-serif;
    font-size: 24px;
    line-height: 1.2;
    color: $gray5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__info {
    font-size: 0;
  }

  &__size,
  &__extension {
    font-family: $fontBook, Arial, Helvetica, sans-serif;
    font-size: 24px;
    color: $grayBlue4;
  }

  &__extension {
    display: inline-block;
    margin-right: 8px;
    text-transform: uppercase;
  }

  &.error {
    border-color: $red;
    transition: all 0.2s ease-out;
  }

  @media #{$desktop-small-down} {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding: 12px 44px 8px 16px;

    &__filename,
    &__extension,
    &__size {
      font-size: 18px;
      line-height: 1.45;
    }
  }

}
