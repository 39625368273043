.partners {
  position: relative;

  &__title {
    font-size: 24px;
    line-height: 32px;
    color: $grayBlue4;
    margin: 0 0 16px;

    @media #{$desktop-small-down} {
      font-size: 18px;
      line-height: 24px;
    }

  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }

  &__item {
    position: relative;
    margin-right: 16px;
    margin-bottom: 16px;

    @media #{$tablet-down} {
      margin-right: 12px;
      margin-bottom: 12px;
    }

    @media #{$mobile-down} {
      width: calc(50% - 6px);
      margin: 0 12px 12px 0;

      &:nth-of-type(2n) {
        margin-right: 0;
      }

      &:before {
        content: "";
        display: block;
        width: 100%;
        padding-bottom: 53%;
      }

    }

  }

}

.partnerCard {
  width: 184px;
  height: 108px;
  padding: 0 19px;
  border: 1px solid $grayBlue3;
  border-radius: 20px;
  box-sizing: border-box;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.06);
  position: relative;
  background: white;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  
  @media #{$desktop-small-down} {
    width: 132px;
    height: 76px;
  }

  @media #{$mobile-down} {
    width: 122px;
    height: 64px;
  }

  &__logo {
    display: block;
    position: relative;
    width: 144px;
    height: 44px;

    &.half-height-square {
      width: 54px;
      height: 54px;

      @media #{$desktop-small-down} {
        width: 38px;
        height: 38px;
      }
      
      @media #{$mobile-down} {
        width: 46px;
        height: 46px;
      }

      @media #{$mobile-small-down} {
        height: 26px;
        width: 26px;
      }
    }
    &.vipservice {
      height: 49px;
      @media #{$desktop-small-down} {
        height: 31px;
      }
      @media #{$mobile-down} {
        width: 102px;
        height: 34.7px;
      }
      @media #{$mobile-small-down} {
        height: 18px;
        width: 55px;
      }
    }
    &.nic{
      height: 62px;
      @media #{$desktop-small-down} {
        height: 39.6px;
      }
      @media #{$mobile-down} {
        height: 44px;
      }
      @media #{$mobile-small-down} {
        height: 24px;
        width: 55px;
      }
    }

    @media #{$desktop-small-down} {
      width: 92px;
      height: 28px;
    }
    
    @media #{$mobile-down} {
      width: 102px;
      height: 32px;
    }

    @media #{$mobile-small-down} {
      height: 20px;
      width: 55px;
    }
  }

  &.inList {

    @media #{$mobile-down} {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

  }

}
