html, body, div, section, header, footer, aside, nav, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre, article,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, input {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  line-height: 1;
  box-sizing: border-box;
  -webkit-appearance: none;
}

table, caption, tbody, tfoot, thead, tr, th, td {
  border-spacing: 0;
  border-collapse:collapse;
  padding: 0;
  margin: 0;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

.clr {
  clear: both !important;
  height: 0 !important;
  line-height: 0 !important;
  display: block !important;
  float: none !important;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
}

::-ms-clear {
  display: none;
}
