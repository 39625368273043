.coder-technologies {

  &__list {
    display: flex;
  }

  &__item {
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-right: 40px;
      width: calc((100% - 40px) * 0.7633);

      .coder-technologies {
        &__inner-list {
          display: flex;
        }

        &__inner-item {
          width: 33.3%;
        }
      }
    }

    &:last-child {
      width: calc((100% - 40px) * 0.2367);
    }
  }

  &__inner-list {
    padding-top: 48px;
    padding-bottom: 48px;
    flex-grow: 1;
    background: #fff;
    border: 1px solid $grayBlue3;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.06);
  }

  &__item-title {
    margin-bottom: 32px;
    text-align: center;
    font-family: $fontDemi, Arial, Helvetica, sans-serif;
    font-size: 40px;
    line-height: 1.3;
    color: $gray5;
  }

  &__item-subtitle {
    margin-bottom: 54px;
    text-align: center;
    font-family: $fontDemi, Arial, Helvetica, sans-serif;
    font-size: 32px;
    line-height: 1.3;
    color: $gray5;
  }

  &__inner-subitem {
    margin-bottom: 48px;
    height: 48px;
    transition: opacity 0.2s ease-in-out;

    &:last-child {
      margin-bottom: 0;
    }

    img {
      margin: 0 auto;
      display: block;
      height: 100%;
      width: auto;
    }
  }

  .tech-title {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-family: $fontMedium;
    font-size: 32px;
    color: #a3b3cb;
  }

  @media screen and (max-width: 1200px) {
    &__list {
      flex-wrap: wrap;
    }

    &__item {

      &:nth-child(n) {
        margin-right: 0;
        margin-bottom: 40px;
        width: 100%;

        .coder-technologies {
          &__inner-list {
            padding-left: 12.3%;
            padding-right: 12.3%;
          }
        }
      }

      &:last-child {
        .coder-technologies {

          &__inner-sublist {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: -16px -28px;
          }

          &__inner-subitem {
            margin: 16px 28px;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .tech-title {
      font-size: 24px;
    }
  }

  @media #{$desktop-small-down} {

    &__inner-list {
      padding-top: 32px;
      padding-bottom: 32px;
    }

    &__item-title {
      margin-bottom: 24px;
      font-size: 32px;
      line-height: 1.3;
    }

    &__item-subtitle {
      margin-bottom: 32px;
      font-size: 24px;
      line-height: 1.32;
    }

    &__inner-subitem {
      height: 32px;
      margin-bottom: 32px;
    }

    &__item {
      &:first-child {
        .coder-technologies {
          &__inner-sublist {
            margin: -16px 12px;
          }

          &__inner-subitem {
            margin: 16px 12px;
          }
        }
      }

      &:nth-child(n) {
        margin-bottom: 32px;

        .coder-technologies {
          &__inner-list {
            padding-left: 5%;
            padding-right: 5%;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (max-width: 900px) {
    //&__item {
    //  &:first-child {
    //    .coder-technologies {
    //      &__inner-list {
    //        padding-left: 13%;
    //        padding-right: 13%;
    //      }
    //
    //      &__inner-item {
    //        &:first-child {
    //          width: 26%;
    //        }
    //
    //        &:nth-child(2) {
    //          width: 41%;
    //        }
    //
    //        &:last-child {
    //          width: 33%;
    //        }
    //      }
    //    }
    //  }
    //}
  }

  @media #{$tablet-down} {
    &__item {
      &:nth-child(n) {
        .coder-technologies {
          &__inner-list {
            flex-wrap: wrap;
          }

          &__inner-item {
            margin-bottom: 32px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          &__inner-item:nth-child(n) {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
          }

          &__item-subtitle {
            width: 100%;
          }

          &__inner-sublist {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: -16px -12px;
            width: 100%;
          }

          &__inner-subitem {
            margin: 16px 12px;
          }
        }
      }

      &:last-child {
        .coder-technologies {
          &__inner-list {
            padding-left: 28px;
            padding-right: 28px;
          }
        }
      }
    }
  }

  @media #{$mobile-down} {
    .coder-technologies__item {
      &:nth-child(n) {
        .coder-technologies {
          &__inner-list {
            display: block;
          }

          &__inner-sublist {
            display: block;
            margin: 0 auto;
          }

          &__inner-subitem {
            margin: 0 0 32px 0;

            &:last-child {
              margin-bottom: 0;
            }
          }

          &__item-subtitle {
            text-align: center;
          }
        }
      }
    }
  }
}
