.outstaff-benefits {
  &__cards {
    margin-top: 96px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    @media #{$desktop-small-down} {
      margin-top: 56px;
    }
  }

  &__card {
    width: calc((100% - 176px) * 0.3333);
    margin-bottom: 72px;

    &:nth-child(3n + 1),
    &:nth-child(3n + 2) {
      margin-right: 88px;
    }

    @for $i from 0 through 3 {
      &:nth-last-child(#{$i}) {
        margin-bottom: 0;
      }
    }

    @media #{$desktop-pre-mid-down} {
      width: calc((100% - 56px) * 0.5);
      margin-bottom: 40px;

      &:nth-child(3n + 1),
      &:nth-child(3n + 2) {
        margin-right: 0;
      }

      &:nth-child(odd) {
        margin-right: 56px;
      }

      @for $i from 1 through 3 {
        &:nth-last-child(#{$i}) {
          margin-bottom: 40px;
        }
      }

      @for $i from 1 through 2 {
        &:nth-last-child(#{$i}) {
          margin-bottom: 0;
        }
      }
    }

    @media #{$tablet-down} {
      width: 99%;

      &:nth-child(odd) {
        margin-right: 0;
      }

      @for $i from 1 through 3 {
        &:nth-last-child(#{$i}) {
          margin-bottom: 40px;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__title-card {
    margin-bottom: 8px;
    font-family: $fontDemi, Arial, Helvetica, sans-serif;
    font-size: 32px;
    color: $gray5;
    line-height: 1.3;

    @media #{$desktop-small-down} {
      font-size: 24px;
    }
  }

  &__text {
    font-family: $fontBook, Arial, Helvetica, sans-serif;
    color: $grayBlue5;
    font-size: 24px;
    line-height: 1.5;

    @media #{$desktop-small-down} {
      font-size: 18px;
    }
  }
}
