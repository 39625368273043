@import "src/css/includes/breakpoints";
@import "src/css/includes/fonts";
@import "src/css/includes/theme";

.footer {
  color: $grayBlue5;
  background: $grayBlue1;

  &__logo {
    display: none;
    width: 132px;
    height: 96px;

    @media only screen and (max-width: 768px) {
      display: flex;
      width: 96px;
      height: 70px;
      margin-top: 48px;
      margin-bottom: 24px;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__in {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 64px;
    padding-bottom: 42px;
  }

  &__info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, auto);
    row-gap: 40px;
    column-gap: 50px;
    width: calc(100% - 220px);
    margin-bottom: 60px;

    @media #{$desktop-down} {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, auto);
    }

    @media #{$desktop-tiny-down} {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, auto);
    }

    @media #{$desktop-pre-mid-only} {
      width: 100%;
      margin-bottom: 40px;
    }

    @media #{$tablet-mid-down} {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(5, auto);
    }
  }

  &__nav {
    grid-row: span 3;
    @media #{$desktop-down} {
      grid-row: 1;
    }
    @media #{$desktop-tiny-down} {
      grid-column: span 3;
    }
    @media #{$tablet-mid-down} {
      grid-column: auto;
    }
  }

  &__outstaff {
    grid-row: span 3;
    @media #{$desktop-down} {
      grid-row: 1;
    }
    @media #{$desktop-tiny-down} {
      grid-row: 2;
      grid-column: 1 / 3;
    }
    @media #{$tablet-mid-down} {
      grid-column: auto;
    }
  }

  &__industries {
    @media #{$desktop-down} {
      grid-row: 2;
      grid-column: span 3;
    }

    @media #{$desktop-tiny-down} {
      grid-row: 3;
      grid-column: span 3;
    }
  }

  &__documents {
    align-self: end;
    @media #{$desktop-down} {
      align-self: auto;
      grid-row: 3;
      grid-column: 2;
    }
    @media #{$desktop-tiny-down} {
      grid-row: 4;
      grid-column: 2;
    }
    @media #{$tablet-mid-down} {
      grid-row: 5;
      grid-column: auto;
    }
  }

  &__audit {
    @media #{$desktop-down} {
      grid-row: 3;
      grid-column: 1;
    }
    @media #{$desktop-tiny-down} {
      grid-row: 4;
    }
  }

  &__section-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 60px;

    @media #{$desktop-pre-mid-down} {
      flex-direction: row;
      width: 100%;
    }
    @media #{$tablet-mid-down} {
      flex-direction: column;
    }
  }

  &__accreditation-wrapper {
    display: flex;
    align-items: center;
    gap: 40px;
    @media only screen and (max-width: 500px) {
      flex-direction: column;
      align-items: flex-start;
    }

    &__accreditation-box {
      display: flex;
      gap: 12px;
      align-items: center;
      color: #a3b3cc;
    }
  }

  &__partner {
    width: 170px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    height: min-content;
    margin-left: 20px;

    @media #{$desktop-pre-mid-down} {
      margin-left: 0;
    }
  }

  &__partner-img {
    display: block;
    width: 170px;
    height: 51px;
  }

  &__social-links {
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 16px;

    @media #{$tablet-mid-down} {
      align-items: start;
    }

    @media #{$mobile-down} {
      gap: 14px;
    }
  }

  &__social-links-row {
    display: flex;
    align-items: center;
    gap: 16px;
    width: max-content;

    @media #{$mobile-down} {
      gap: 14px;
    }

    &__element {
      position: relative;

      &__link {
        display: block;
        width: 100%;
        text-align: center;
        outline: none;

        .icon-block {
          margin: 0 auto;
        }

        &:hover,
        &:focus {
          fill: #a3b3cc;
        }
      }
    }
  }

  &__section-title {
    margin-bottom: 16px;
    font-family: $fontDemi, Arial, Helvetica, sans-serif;
    font-size: 24px;
    line-height: 1.5;
  }

  &__link {
    font-family: $fontMedium;
    font-size: 18px;
    line-height: 1.45;
    color: $grayBlue5;
    text-decoration: none;
    transition: all 0.2s ease-out;
    outline: none;

    &_mail {
      margin-right: 40px;
      border-bottom: 1px solid $grayBlue5;

      &:hover,
      &:focus {
        border-bottom-color: $grayBlue6;
      }
    }

    &:hover,
    &:focus {
      color: $grayBlue6;
      transition: all 0.2s ease-out;
    }
  }

  &__list {
    display: grid;
    column-gap: 40px;
    row-gap: 16px;
    grid-template-columns: auto;

    @media #{$desktop-pre-mid-down} {
      column-gap: 50px;
    }

    &_nav {
      grid-auto-flow: column;
      grid-template-columns: repeat(2, max-content);
      grid-template-rows: repeat(10, auto);

      @media #{$desktop-tiny-down} {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(6, auto);
      }

      @media #{$tablet-down} {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(8, auto);
      }

      @media #{$tablet-mid-down} {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(17, auto);
      }
    }

    &_outstaff {
      grid-auto-flow: column;
      grid-template-columns: repeat(2, max-content);
      grid-template-rows: repeat(10, auto);

      @media #{$desktop-tiny-down} {
        grid-auto-flow: row;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: unset;
      }

      @media #{$tablet-down} {
        grid-template-columns: repeat(2, 1fr);
      }

      @media #{$tablet-mid-down} {
        grid-template-columns: 1fr;
      }
    }

    &_documents {
      grid-template-columns: 1fr;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    font-size: 20px;
    width: 100%;
    justify-content: space-between;
    gap: 24px;

    .footer__link {
      display: inline-block;
      font-size: 20px;
    }

    @media only screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;
    }
  }

  &__copyright {
    display: inline-block;
    margin-left: auto;
    font-family: $fontMedium, Arial, Helvetica, sans-serif;
    font-size: 20px;
    color: $grayBlue4;
  }

  @media #{$desktop-small-down} {
    &__in {
      padding-top: 48px;
    }

    &__info {
      width: 100%;
    }

    &__bottom {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__copyright {
      margin-left: 0;
    }
  }

  @media #{$mobile-down} {
    &__bottom {
      flex-direction: column;
      align-items: flex-start;
    }

    &__link_mail {
      margin-right: 40px;
      margin-bottom: 16px;
    }
  }
}
